import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'go-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() public type: 'basic' | 'stroked' | 'flat' | 'icon' = 'basic';
  @Input() public color?: string;
  @Input() public loading = false;
  @Input() public autoWidth = true;
  @Input() public disabled = false;

  @Output() public clicked: EventEmitter<void>;

  constructor() {
    this.clicked = new EventEmitter();
  }

  public onButtonClicked(): void {
    this.clicked.emit();
  }
}
