import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { AbstractInterceptor } from './abstract-interceptor';

@Injectable()
export class AuthenticationInterceptor extends AbstractInterceptor {

  constructor(
    private userService: UserService,
    private router: Router
  ) {
    super();
  }

  protected modifyRequest(req: HttpRequest<any>): HttpRequest<any> {
    if (!this.userService) {
      return req;
    }

    const authHeaderValue = this.userService.getBasicAuthToken();

    let httpHeaders = req.headers;
    if (authHeaderValue && !httpHeaders.has('Authorization')) {
      httpHeaders = httpHeaders.set('Authorization', authHeaderValue);
    }

    return req.clone({
      headers: httpHeaders,
      withCredentials: true
    });
  }

  protected handleErrorResponse(res: HttpErrorResponse): boolean {
    if (res.status === 401 && this.router.url.indexOf('/login') < 0) {
      // if not authenticated
      if (this.userService.isLoggedIn()) {
        this.userService.logout();
        this.router.navigateByUrl('/login');
      } else {
        const responseMessage = this.getResponseMessageFrom(res.error);
        this.showDialog(responseMessage, 'httpservice.dialog.notallowed');
      }
    } else {
      return false;
    }

    return true;
  }
}
