import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

@Component({
  selector: 'go-spinner, [go-spinner]',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnChanges, OnDestroy {

  @Input() public size: 'tiny' | 'small' | 'normal' | 'big' = 'normal';
  @Input() public bgColor: 'primary' | 'gray' | 'invert' = 'invert';
  @Input() public isRound = false;
  @Input() public loadingText?: string;
  @Input() public timeBeforeLoadingTextIsShownInMs?: number;

  public showLoadingText = false;
  private timeoutId: ReturnType<typeof setTimeout>;

  constructor() { }

  public ngOnChanges(): void {
      if (this.loadingText && this.timeBeforeLoadingTextIsShownInMs) {
          this.timeoutId = setTimeout(() => {
              this.showLoadingText = true;
          }, this.timeBeforeLoadingTextIsShownInMs);
      }
  }

  public ngOnDestroy(): void {
      if (this.timeoutId) {
          clearTimeout(this.timeoutId);
      }
  }
}
