import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export abstract class AbstractInterceptor implements HttpInterceptor {

  constructor() { }

  /**
   * Can be overwritten from implementations to manipulate the http request.
   */
  protected modifyRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req;
  }

  /**
   * Can be overwritten from implementations to handle (specific) error responses.
   * @returns true if the error was handled, else false
   */
  protected handleErrorResponse(res: HttpErrorResponse): boolean {
    return false;
  }

  protected getResponseMessageFrom(responseBody: any): string {
    return (responseBody && responseBody.message) ? responseBody.message : '';
  }

  protected showDialog(serverResponseMessage: string, dialogContentTranslationKey: string): void {
    // TODO: implement when needed
    /*let message;

    this.translateService.get(dialogContentTranslationKey).subscribe((translation) => {
      if (!serverResponseMessage || serverResponseMessage === '') {
        message = translation.message;
      } else {
        message = serverResponseMessage;
      }
      dialogs.alert({
        title: translation.title,
        message: message,
        okButtonText: translation.ok ? translation.ok : 'OK'
      });
    });*/
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.modifyRequest(req))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error && this.handleErrorResponse(error)) {
            return throwError(null);
          } else {
            return throwError(error);
          }
        }),
      );
  }
}
