import { Component, Input } from '@angular/core';
import { MenuItem } from '~/shared/dto/menu-item';

@Component({
  selector: 'go-menu-icon',
  templateUrl: './menu-icon.component.html',
  styleUrls: ['./menu-icon.component.scss']
})
export class MenuIconComponent {

  @Input() public iconName: string;
  @Input() public items: Array<MenuItem> = [];
  @Input() public tooltip?: string;

  constructor() {
  }

}
