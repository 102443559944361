import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

const RANGE_LABEL_OF = 'shared.pagination.of';
const ITEMS_PER_PAGE = 'shared.pagination.itemPerPage';
const NEXT_PAGE = 'shared.pagination.nextPage';
const PREV_PAGE = 'shared.pagination.previousPage';
const FIRST_PAGE = 'shared.pagination.firstPage';
const LAST_PAGE = 'shared.pagination.lastPage';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl {
  private ofTranslation: string;

  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${ this.ofTranslation } ${ length }`;
    }

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${ startIndex + 1 } - ${ endIndex } ${ this.ofTranslation } ${ length }`;
  }

  public getAndInitTranslations(): void {
    this.translate.get([
      RANGE_LABEL_OF,
      ITEMS_PER_PAGE,
      NEXT_PAGE,
      PREV_PAGE,
      FIRST_PAGE,
      LAST_PAGE,
    ])
      .subscribe((translation: any) => {
        this.ofTranslation = translation[RANGE_LABEL_OF];
        this.itemsPerPageLabel = translation[ITEMS_PER_PAGE] + ':';
        this.nextPageLabel = translation[NEXT_PAGE];
        this.previousPageLabel = translation[PREV_PAGE];
        this.firstPageLabel = translation[FIRST_PAGE];
        this.lastPageLabel = translation[LAST_PAGE];

        this.changes.next();
      });
  }
}
