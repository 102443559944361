import { Directive, Input, Output, HostListener, EventEmitter } from '@angular/core';

// see https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard/52949299#52949299
@Directive({
  selector: '[goCopyToClipboard]'
})
export class CopyToClipboardDirective {

  @Input()
  public goCopyToClipboard: string;

  @Output()
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {

    event.preventDefault();
    if (!this.goCopyToClipboard) {
      return;
    }

    const listener = (e: ClipboardEvent) => {
      const clipboard = e.clipboardData || window['clipboardData'];
      clipboard.setData('text', this.goCopyToClipboard.toString());
      e.preventDefault();

      this.copied.emit(this.goCopyToClipboard);
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }

}
