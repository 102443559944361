import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ButtonComponent } from './components/button/button.component';
import { HeaderComponent } from './components/header/header.component';
import { LanguageSwitcherComponent } from './components/header/language-switcher/language-switcher.component';
import { MenuIconComponent } from './components/header/menu-icon/menu-icon.component';
import { SpinnerOverlayComponent } from './components/spinner/spinner-overlay.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';
import { MatPaginatorI18nService } from './services/mat-paginator-i18n.service';
import { MatSnackBarModule, MatNativeDateModule } from '@angular/material';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    ButtonComponent,
    SpinnerComponent,
    SpinnerOverlayComponent,
    LanguageSwitcherComponent,
    MenuIconComponent,
    HeaderComponent,
    CopyToClipboardDirective
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatMenuModule,
    DragDropModule,
    MatPaginatorModule
  ],
  exports: [
    TranslateModule,
    ButtonComponent,
    SpinnerComponent,
    SpinnerOverlayComponent,
    LanguageSwitcherComponent,
    MenuIconComponent,
    HeaderComponent,
    CopyToClipboardDirective,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatMenuModule,
    DragDropModule,
    MatPaginatorModule
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: MatPaginatorIntl,
          useClass: MatPaginatorI18nService,
        },
      ]
    };
  }

  constructor() {
    console.log('Shared constructor');
  }
}
