import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Config } from '~/config';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private tranlateSubscription: Subscription;

  constructor(
    private titleService: Title,
    private translateService: TranslateService,
  ) { }

  private resetSubscription(): void {
    if (this.tranlateSubscription) {
      this.tranlateSubscription.unsubscribe();
      this.tranlateSubscription = null;
    }
  }

  public changeTitleSuffix(translateKey: string): void {
    this.resetSubscription();

    this.tranlateSubscription = this.translateService.get(translateKey).subscribe(suffix => {
      this.titleService.setTitle(`${ Config.websiteTitle } | ${ suffix }`);
    });
  }

  public resetTitle(): void {
    this.resetSubscription();
    this.titleService.setTitle(Config.websiteTitle);
  }
}
