import { AbstractInterceptor } from './abstract-interceptor';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Config } from '~/config';

@Injectable()
export class CommonInterceptor extends AbstractInterceptor {

  constructor() {
    super();
  }

  protected modifyRequest(req: HttpRequest<any>): HttpRequest<any> {
    // add common headers
    const httpHeaders = req.headers
      .set('Application', Config.applicationName);

    return req.clone({
      headers: httpHeaders
    });
  }
}
