import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from '~/shared/services/title.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'go-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  private queryParamSubscription: Subscription;
  private urlAfterLogin: string;

  public hidePassword: boolean;
  public loading: boolean;
  public error: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    titleService: TitleService
  ) {
    titleService.changeTitleSuffix('login.title');

    this.hidePassword = true;
    this.loading = false;
    this.error = null;
  }

  public ngOnInit(): void {
    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      this.urlAfterLogin = params['redirect'] || '/';
    });
  }

  public ngOnDestroy(): void {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  public toggleHidePasswort(): void {
    this.hidePassword = !this.hidePassword;
  }

  public onSubmitForm(e: Event): void {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  public login(username: string, password: string): void {
    this.loading = true;
    this.userService.login(username, password)
      .subscribe(() => {
        this.router.navigateByUrl(this.urlAfterLogin);
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.error = error.error.message;
      });
  }

}
