import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MenuItem } from '~/shared/dto/menu-item';
import { LanguageService } from '~/shared/services/language.service';

@Component({
  selector: 'go-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent {

  public currentLanguage: Observable<string>;
  public languages: Array<MenuItem>;

  constructor(private languageService: LanguageService) {
    this.languages = languageService.availableLanguages().map(lang => {
      return {
        name: lang.name,
        onClick: () => this.languageService.changeLanguage(lang.token)
      } as MenuItem;
    });

    this.currentLanguage = languageService.currentLanguage()
      .pipe(tap(lang => {
        this.languages = this.languages.map(language => {
          return { ...language, selected: language.name === lang };
        });
      }));
  }
}
