import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './shared/components/login/login.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', loadChildren: './landingpage/landingpage.module#LandingpageModule' },
  { path: 'login', component: LoginComponent },
  { path: 'qr', loadChildren: './qr-generator/qr-generator.module#QrGeneratorModule' },
  { path: 'survey', loadChildren: './survey/survey.module#SurveyModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 70]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
