// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const Config = {
  production: false,
  websiteTitle: 'icos go',
  applicationName: 'icos website',
  wholeAssortmentOrderTemplateId: '_ALL_',
  languageKey: 'language',
  completedSurveyKey: 'completed_survey',
  apiUrl: 'https://master.stage.icos-go.de/api/icos/',
  languages: ['de', 'en', 'fr', 'nl', 'pl'],
  pdfGenerationRequestTimeout: 120 * 1000,
  pdfGenerationRetryInterval: 5000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
