import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeSl from '@angular/common/locales/sl';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Config } from '~/config';

export interface Language {
  token: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private readonly DEFAULT_LANGUAGE_TOKEN = 'de';
  private readonly LANGUAGE_TRANSLATIONS = {
    'de': 'Deutsch',
    'en': 'English',
    'fr': 'Français',
    'nl': 'Nederlands',
    'pl': 'Polski'
  };

  private currentLangSubject: Subject<string>;

  constructor(private translateService: TranslateService) {
    this.registerAllLocaleData();

    this.currentLangSubject = new ReplaySubject(1);

    translateService.onLangChange.asObservable()
      .pipe(
        tap(event => localStorage.setItem(Config.languageKey, event.lang)),
        map(event => event.lang)
      ).subscribe(this.currentLangSubject);
  }

  private registerAllLocaleData(): void {
    // we have to register the locale data for all possible language
    // see https://angular.io/guide/i18n#setting-up-the-locale-of-your-app
    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeNl, 'nl');
    registerLocaleData(localePl, 'pl');
  }

  private detectCurrentLanguage(): string {
    const browserLanguageToken = navigator.language.split('-')[0];
    let currentlanguage = localStorage.getItem(Config.languageKey);

    if (!currentlanguage && Config.languages.indexOf(browserLanguageToken) >= 0) {
      currentlanguage = browserLanguageToken;
    }

    return currentlanguage || this.DEFAULT_LANGUAGE_TOKEN;
  }

  private translateLanguage(lang: string): string {
    return this.LANGUAGE_TRANSLATIONS[lang] || lang;
  }

  public init(): void {
    const currentlanguage = this.detectCurrentLanguage();

    this.translateService.setDefaultLang(this.DEFAULT_LANGUAGE_TOKEN);
    this.translateService.use(currentlanguage);

    this.currentLangSubject.next(currentlanguage);
  }

  // returns the code of the current language
  public currentLang(): Observable<string> {
    return this.currentLangSubject.asObservable();
  }

  // returns the current langague as a translated string
  public currentLanguage(): Observable<string> {
    return this.currentLangSubject.asObservable()
      .pipe(map(lang => this.translateLanguage(lang)));
  }

  public availableLanguages(): Array<Language> {
    return Config.languages.map(token => (
      { token, name: this.translateLanguage(token) }
    ));
  }

  public changeLanguage(languageToken: string): void {
    this.translateService.use(languageToken);
  }
}
