import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'go-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent {

  @Input() public size: 'small' | 'normal' | 'big' = 'normal';
  @Input() public hidden: boolean;

  constructor() { }
}
