import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { LanguageService } from './shared/services/language.service';
import { TitleService } from './shared/services/title.service';

@Component({
  selector: 'go-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public hideLoadingSpinner: Observable<boolean>;

  constructor(languageService: LanguageService, titleService: TitleService, router: Router) {
    languageService.init();
    titleService.resetTitle();

    this.hideLoadingSpinner = router.events.pipe(
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
      debounceTime(100),
      map(event => event instanceof NavigationEnd)
    );
  }
}
