import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Config } from '~/config';
import * as utf8 from 'utf8';
import { User } from '../dto/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly BASIC_AUTH_TOKEN_KEY = 'BASIC_AUTH_TOKEN';

  private basicAuthToken: string;
  private user: ReplaySubject<User>;

  constructor(private http: HttpClient) {
    this.basicAuthToken = localStorage.getItem(this.BASIC_AUTH_TOKEN_KEY);
    this.user = new ReplaySubject(1);

    if (this.basicAuthToken) {
      const headers = new HttpHeaders({ 'Authorization': this.basicAuthToken });
      this.updateUser(headers).subscribe(
        () => { /* all successful */ },
        _ => {
          this.user.next(null);
        }
      );
    } else {
      this.user.next(null);
    }
  }

  private createBasicAuthToken(username: string, password: string): string {
    if (username && password) {
      return 'Basic ' + btoa(utf8.encode(username + ':' + password));
    }
    return null;
  }

  private updateUser(headers?: HttpHeaders): Observable<void> {
    return this.http.get<User>(Config.apiUrl + 'user', { headers })
      .pipe(map(user => {
        this.user.next(user);
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  public login(username: string, password: string): Observable<void> {
    const basicAuthToken = this.createBasicAuthToken(username, password);
    const headers = new HttpHeaders({ 'Authorization': basicAuthToken });
    return this.updateUser(headers).pipe(tap(() => {
      this.basicAuthToken = basicAuthToken;
      localStorage.setItem(this.BASIC_AUTH_TOKEN_KEY, basicAuthToken);
    } ));
  }

  public logout(): void {
    localStorage.removeItem(this.BASIC_AUTH_TOKEN_KEY);
    this.user.next(null);
  }

  public getBasicAuthToken(): string {
    return this.basicAuthToken;
  }

  public isLoggedIn(): Observable<boolean> {
    return this.user.pipe(
      take(1),
      map(user => user !== null)
    );
  }

  public getCurrentUser(): Observable<User> {
    return this.user.asObservable();
  }
}
